import React, { useEffect, useState } from "react"
import { Flex, Text } from "theme-ui"
import styled from "@emotion/styled"

import Arrow from "../../../assets/svg/arrow-left.svg"
import ReturnOrder from "../../self-return/return-order"
import Medusa from "../../../services/api"
import { useNotificationContext } from "../../../context/NotificationContext"

const Container = styled(Flex)`
  flex-direction: column;
  min-height: 100vh;
  flex: 1;
  color: ${(props) => props.theme.colors.primary};
  padding: 0 16px;

  .order-container {
    width: 100%;
    margin: initial;
  }

  .order-list,
  .shipping-container,
  .billing-container {
    margin-bottom: 16px;
    background-color: ${(props) => props.theme.colors.grayscale.white};
    border: ${(props) => props.theme.borders.grey};
  }

  .order-list,
  .billing-container {
    padding: 8px 16px;
  }

  .billing-information {
    padding: 0;
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    padding: 0 0;
  }
`

const ReturnPage = ({ order, onClose }) => {
  const { pushNotification } = useNotificationContext()
  const [newOrder, setNewOrder] = useState()
  const [requestApproved, setRequestApproved] = useState(false)

  useEffect(() => {
    const load = async () => {
      let updatedOrder = order
      const returnReasons = await Medusa.returnReasons
        .list()
        .then(({ data }) => data.return_reasons)
        .catch((err) => console.error(err))

      updatedOrder.return_reasons = returnReasons.reduce((acc, next) => {
        const result = next
        result.display = next.label

        acc.push(result)
        return acc
      }, [])

      const shippingOptions = await Medusa.shippingOptions
        .list({
          is_return: true,
          region_id: order.region_id,
        })
        .then(({ data }) => data.shipping_options)
        .catch((err) => console.error(err))

      const options = shippingOptions
      const selfReturnOption = {
        name: "self_return",
        id: "self-return-option",
        amount: 0,
      }
      // Append the free option
      options.push(selfReturnOption)
      updatedOrder.return_shipping_options = options
      updatedOrder.return_shipping = {
        option_id: selfReturnOption.id,
        amount: selfReturnOption.price_incl_tax,
        name: selfReturnOption.name,
      }
      updatedOrder.shipping_options = shippingOptions
      updatedOrder.additional_items = []

      setNewOrder(updatedOrder)
    }

    load()
  }, [order])

  const handleSwapSubmit = () => {
    try {
      const items = newOrder.items.reduce(
        (acc, next) => {
          if (next.checked) {
            if (!next.reason?.value) {
              throw new Error("no-reason")
            } else {
              acc.return_items.push({
                item_id: next.id,
                quantity: next.quantity,
                reason_id: next.reason.id,
              })

              if (next.swap_to) {
                acc.additional_items.push({
                  variant_id: next.swap_to.id,
                  quantity: next.quantity,
                })
              }
            }
          }

          return acc
        },
        { return_items: [], additional_items: [] }
      )

      if (items.length === 0) {
        throw new Error("no-items")
      }

      let swapOrder = {
        order_id: order.id,
        additional_items: items.additional_items,
        return_items: items.return_items,
      }

      // If the return is self return, dont append return_shipping to the API submit
      if (newOrder.return_shipping.option_id !== "self-return-option") {
        swapOrder.return_shipping_option = newOrder.return_shipping.option_id
      }

      let returnOrder
      if (swapOrder.additional_items.length === 0) {
        returnOrder = {
          order_id: swapOrder.order_id,
          items: swapOrder.return_items,
        }
        if (newOrder.return_shipping.option_id !== "self-return-option") {
          returnOrder.return_shipping = {
            option_id: newOrder.return_shipping.option_id,
          }
        }
      }

      const isReturn = Boolean(returnOrder.order_id)
      const payload = isReturn ? returnOrder : swapOrder

      Medusa[isReturn ? "returns" : "swaps"]
        .create(payload)
        .then(() => {
          pushNotification({
            id: "return-success",
            body: "Your return request has been received. Please follow the steps below to return items safely to our facilities.",
            dismiss: {
              duration: 3000,
            },
          })
          setRequestApproved(true)
        })
        .catch((err) => {
          console.error(err)

          pushNotification({
            id: "cant-swap",
            body: "Cannot create the exchange",
            dismiss: {
              duration: 3000,
            },
          })
        })
    } catch (err) {
      if (err.message === "no-reason") {
        pushNotification({
          id: "no-reason",
          body: "Remember to choose a reason",
          dismiss: {
            duration: 3000,
          },
        })
      } else if (err.message === "no-items") {
        pushNotification({
          id: "no-items",
          body: "No items were chosen for return",
          dismiss: {
            duration: 3000,
          },
        })
      } else {
        pushNotification({
          id: "cant-swap",
          body: "Cannot create the exchange",
          dismiss: {
            duration: 3000,
          },
        })
      }
    }
  }

  return (
    <Container className="order-details">
      <Flex
        onClick={onClose}
        sx={{
          pb: "16px",
          fontSize: "14px",
          fontWeight: "bold",
          alignItems: "center",
          cursor: "pointer",
          ".go-back-arrow": { marginRight: "8px" },
        }}
      >
        <Arrow className="go-back-arrow" />
        <Text>go back</Text>
      </Flex>
      {newOrder && !requestApproved && (
        <ReturnOrder
          order={newOrder}
          setOrder={setNewOrder}
          handleSwapSubmit={handleSwapSubmit}
        />
      )}
      {requestApproved && (
        <Flex
          sx={{
            flexDirection: "column",
            p: "3em",
            ".step-header": {
              fontWeight: "bold",
            },
          }}
        >
          <h2 className="text">
            Your return request has been received. Please follow the steps below
            to return items safely to our facilities.
          </h2>
          {order.return_shipping?.option_id === "self-return-option" ? (
            <div>
              <div>
                <p className="step-header">Step 1</p>
                <p>
                  Secure your items inside the original Tekla packaging and take
                  it to a courier of your choice.
                </p>
              </div>
              <div>
                <p className="step-header">Step 2</p>
                <p>
                  Purchase a label and attach it securely to the package. Make
                  sure to save the tracking code and note the following address
                  for shipping
                </p>
                <div className="logistics-container">
                  <p className="logistics">Att: Tekla Fabric</p>
                  <p className="logistics">Blomstervej 68</p>
                  <p className="logistics">8381 Tilst</p>
                  <p className="logistics">Denmark</p>
                </div>
              </div>
              <div>
                <p className="step-header">Step 3</p>
                <p>
                  Await email confirmation that the package has been received in
                  our facilities and that your refund is on its way to you.
                </p>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <p className="step-header">Step 1</p>
                <p>Print the return label sent to you via email.</p>
              </div>
              <div>
                <p className="step-header">Step 2</p>
                <p>
                  Secure your items inside the original Tekla packaging and
                  adhere the return label to the package.
                </p>
              </div>
              <div>
                <p className="step-header">Step 3</p>
                <p>
                  Drop off the return package to one of the nearest shipping
                  locations for the provider of your choice.
                </p>
              </div>
              <div>
                <p className="step-header">Step 4</p>
                <p>
                  Await email confirmation that the package has been received in
                  our facilities and that your refund is on its way to you.
                </p>
              </div>
            </div>
          )}
        </Flex>
      )}
    </Container>
  )
}

export default ReturnPage
